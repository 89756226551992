const ratePlanAdapter = (data) => {
  let adaptRatePlan = data.map((ratePlan) => {
    return {
      id: ratePlan.id,
      name: ratePlan.rate_plan_name,
      planType: ratePlan.plan_type,
      electricPrices: ratePlan.electric_prices,
      gasPrices: ratePlan.gas_prices,
      telco_phone_lines: ratePlan.telco_phone_lines,
      isIndexedPrice: ratePlan.pricing_type == "indexed_prices",
      code: ratePlan.code,
      businesSection: ratePlan.business_section,
      customerAreas: ratePlan.customer_areas,
      businessSegments: ratePlan.business_segments,
      status: ratePlan.status == "Active" ? "Activado" : "Desactivado",
      provider: ratePlan.provider ? ratePlan.provider.name : null,
    };
  });

  return adaptRatePlan;
};
export default ratePlanAdapter;
